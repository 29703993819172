function fillMaxLengthComments(value) {
    $("[name=CommentsReviewAssistant]").attr("maxlength", value);
    $(".maxLengthComments").text(value); 
}

$("[name=CommentsReviewAssistant]").keyup(function() {
    var length = $(this).val().length;
    $('.currentLengthComments').text(length);
});

function registerCloseReviewAssistantModal(documentTypeId) {
    $("#review-assistant-modal").off('click', '.close-button-review-assistant-modal');
    $("#review-assistant-modal").on('click', '.close-button-review-assistant-modal', function (e) {
        $('body').css('overflow','auto');  
        cleanReviewCommentsData();
        addRedLineDocumentsToAttachmentSection(documentTypeId);
    })
}

function apiPostReviewComments(formId, documentType, commentType, comment, reviewStatusId) {   
   
    var deferred = $.Deferred();
    var jsonItem = {};  
    jsonItem['FormId'] = formId;
    jsonItem['DocumentType'] = documentType;
    jsonItem['CommentType'] = commentType;
    jsonItem['Comment'] = comment;
    jsonItem['CommentAuthor'] = currentUser.email;
    jsonItem['CreatedBy'] = currentUser.userId;
    jsonItem['CommentAuthorName'] = currentUser.userId;
    jsonItem['ReviewStatus'] = reviewStatusId;
    $.ajax({
        url: apiUrl+"CrossBorderComments/Add",
        type: "POST",
        data: JSON.stringify(jsonItem),
        headers: {
            "content-Type": "application/json",
            "Authorization" : getTokenValue(),
            "FormType" : getFormType(),
            "UserRoles": getUserRoles()
        },
        success: function(data){
            deferred.resolve(data);
        },
        error: function(error){
            showError(error);
        }
    })
    return deferred.promise();
}

function getDocumentComments(formId) {
    var deferred = $.Deferred();
    $.ajax({
        url: apiUrl + "CrossBorderComments/GetList?formID="+formId,
        type: "GET",
        headers: {
            "accept": "application/json",
            "Authorization" : getTokenValue(),
            "FormType" : getFormType(),
            "UserRoles": getUserRoles()
        },
        success: function (data) {
            deferred.resolve(data);
        },
        error: function (error) {
            deferred.reject(data);
        }
    });
    return deferred.promise();
}

function cleanReviewCommentsData () {
    $("[name=CommentsReviewAssistant]").val("");
    $('.currentLengthComments').text(0);
    $('[name=DCFInternalComments]').attr("checked", false);
}

function generateReviewCommentWhenCreated(comment, commentType, date, documentTypeId) {
    generateCommentSection(documentTypeId);
    var section = $(".review-comments-document-type-" + documentTypeId);
    var isMemberFirmMember = isMemberOfPeoplePicker();
    addReviewComment (comment, commentType, currentUser.userId, date, section, documentTypeId, isMemberFirmMember);
}
function isMemberOfPeoplePicker() {
    return  internationalMember.map(function(value){ return value.toLowerCase()}).includes(currentUser.email.trim().toLowerCase());
    //  return $("#" + peoplePickerId + "_TopSpan_HiddenInput")[0].value.toString().indexOf(loginName) > -1
  }

function generateCommentSection (documentTypeId){
    var divSection = $("#comment-section-" + documentTypeId);
    if (divSection.find("ul").length == 0) {
        var commentsSectionHtml = '<span id="arrow-comments-' + documentTypeId + '" class="glyphicon comments-down-arrow comments-right-arrow glyphicon-chevron-down visibility-hidden" document-type-comment="' + 
            documentTypeId + '"></span><div class="Comment-Space width-95 display-inline-block comment-section-' + documentTypeId + '"><ul class="ul-no-style no-margin-no-padding review-comments-document-type-' + 
            documentTypeId + '"></ul></div>';
        divSection.append(commentsSectionHtml);
    }
}

function populateCommentsDocumentType(data, isMemberFirmMember,atachments){
    var comments = data;
    if(comments.length>0){
    comments = comments.sort(function (a, b) {
        var dateA = new Date(a.Created).getTime();
        var dateB = new Date(b.Created).getTime();
        return dateA < dateB ? -1 : 1; 
      });
    }
    for (var value in comments){
        var comment = comments[value];
        var commentType = comment.CommentType;
        var author = comment.CommentAuthorName;
        var date = comment.Created;
        var commentText = comment.Comment;
        var documentTypeId="";
        if(documentTypeList != undefined && documentTypeList.length > 0)
        {
           var docTypeObj = documentTypeList.filter(obj => obj.DocumentTypeTitle == comment.DocumentType);
           if(docTypeObj != undefined && docTypeObj != null && docTypeObj.length>0)
               documentTypeId = docTypeObj[0].DocumentTypeId;
        }
            
        generateCommentSection(documentTypeId);  
        var section = $(".review-comments-document-type-" + documentTypeId);        
        addReviewComment (commentText, commentType, author, date, section, documentTypeId, isMemberFirmMember);
    }
}

function addReviewComment (commentText, commentType, author, date, section, documentTypeId, isMemberFirmMember) {
    if ((isMemberFirmMember && commentType == "External") || (!isMemberFirmMember)) {
        var totalComments = section.find("li").length + 1;
        if (totalComments > 1) {
            showArrowComents(documentTypeId);
        }
        $("#comment-section-" + documentTypeId).removeClass("hidden");    
        var newComment = generateReviewCommentsHtml (author, date , commentText, commentType, totalComments, totalComments, documentTypeId);
        section.prepend(newComment);
        showLastComment(section, documentTypeId);
        updateTotalComments (totalComments, documentTypeId)
    }
}

function showLastComment(section, documentTypeId){
    var liExceptFirst = section.find("li").slice(1);
    liExceptFirst.addClass("additional-comment hidden");
    if ($("#arrow-comments-" + documentTypeId).hasClass("glyphicon-chevron-up")) {
        switchArrowClassForComments (documentTypeId);
    }
}

function switchArrowClassForComments (documentTypeId) {
    var arrow = $("#arrow-comments-" + documentTypeId);
    if (arrow.hasClass("glyphicon-chevron-up")) {
        arrow.removeClass("glyphicon-chevron-up");
        arrow.addClass("glyphicon-chevron-down");
    }
    else {
        if (arrow.hasClass("glyphicon-chevron-down")) {
            arrow.removeClass("glyphicon-chevron-down");
            arrow.addClass("glyphicon-chevron-up");
        }  
    }
}

function updateTotalComments (totalComments, documentTypeId) {
    $(".totalComments-" + documentTypeId).each(function() {
        $(this).html("<b>"+ totalComments + "</b>");
    });
}

function generateReviewCommentsHtml (user, date, comment, typeComment, commentNumber, totalComments, documentTypeId) {
    date=ConvertUTCToIST(date);  
    var formatDate = getCSTDateTimeString(date,true);
    var html = '<li class="margin-top-8 height-70 ' + typeComment.toLowerCase() + '-comment"><div class="display-inline-block margin-left-14 ' + typeComment.toLowerCase() + '-comments-bar-color"></div>' +
        '<div class="width-95 bottom-24 display-inline-block div-comments margin-left-8 font-size-12">' +
        '<span class="margin-left-4"><b>' + user + ', ' + formatDate + '</b></span><div class="float-right"><span><b>' + commentNumber + ' of </b></span><span class="totalComments-' + documentTypeId + '"><b>' + totalComments + '</b></span></div><br>' +
        '<textarea readonly rows="3" class="textarea-comments">' + comment + '</textarea></div></li>';
    return html;
}

function showArrowComents(documentTypeId){
    $("#arrow-comments-" + documentTypeId).removeClass("visibility-hidden");
}

$(".attachments-rows").on('click', '.comments-down-arrow', showComments);

function showComments(){
    $(".attachments-rows").off('click', '.comments-down-arrow');
    $(".attachments-rows").on('click', '.comments-down-arrow', showComments);
    var documentTypeId = $(this).attr("document-type-comment");
    var liList = $(".review-comments-document-type-" + documentTypeId).find("li.additional-comment");
    collapseOrExpandComments(liList)
    switchArrowClassForComments (documentTypeId);
}

function collapseOrExpandComments(liList) {
    liList.toggleClass("hidden");
}