
var lengthFileNameDefault = 10;
var lengthCommentsDefault = 280;
var cstToUtcOffsetDefault = 5;

var environmentVariables= getEnvironmentVariable(localStorage.getItem("EnvironmentFlag"));
var apiRoleManagementUrl = environmentVariables[0].apiRoleManagementUrl;

function getCurrentUserName() {
    var endpointUrl = apiUrl + 'web/currentuser';
    return $.ajax({
        url: endpointUrl,
        method: "GET",
        contentType: "application/json;odata=verbose",
        headers: {
            "Accept": "application/json;odata=verbose"
        }
    });
}

//GET USER
function getUsersDataByFormId(id) {
    return $.ajax({
        url: apiUrl + "web/lists/getbytitle('" + enviroment + "')/Items(" + id + ")?$select=MemberFirmTeam/EMail,DCFEngagementTeam/EMail,Series24/EMail,DealTeamBusinessReviewer/EMail&$expand=MemberFirmTeam/EMail,DCFEngagementTeam/EMail,Series24/EMail,DealTeamBusinessReviewer/EMail",

        method: "GET",
        headers: {
            "accept": "application/json;odata=verbose",
        }
    })
}

function getUserId(email) {
    var endpointUrl = apiUrl + "web/siteusers/getbyemail('" + email + "')";
    return $.ajax({
        url: endpointUrl,
        method: "GET",
        contentType: "application/json;odata=verbose",
        headers: {
            "Accept": "application/json;odata=verbose"
        }
    });
}

//scrape values of an url parameter
function GetURLParameter(sParam) {
    var sPageURL = window.location.search.substring(1);
    var sURLVariables = sPageURL.split('&');
    for (var i = 0; i < sURLVariables.length; i++) {
        var sParameterName = sURLVariables[i].split('=');
        if (sParameterName[0] == sParam) {
            return sParameterName[1];
        }
    }
}

function getCurrentUserFormGroup() {
    var deferred = $.Deferred();
    var getURL;
    if(localStorage.getItem("EnvironmentFlag")=="local"){
        getURL = apiRoleManagementUrl+'/api/'+"UserGroups/GetUserGroupsByEmail?email=";
    }
    else{
        getURL = apiUrl+"UserGroups/GetUserGroupsByEmail?email=";
    }


        $.ajax({
          url: getURL+currentUser.email,
          method: "GET",
            headers: {
                "content-Type": "application/json",
                "Authorization" : "Bearer "+localStorage.getItem("idToken"),
                "FormType" : localStorage.getItem("FormType"),
                "UserRoles": getUserRoles()
            },
            success: function (data) {
                data=data.filter(s=>s.Title!=null)
                deferred.resolve(data);
            },
            error: function (data) {
                showError();
                deferred.reject();
            }
        })
    
    return deferred.promise();
}

function getCurrentUserGroups() {
    var deferred = $.Deferred();
    var getURL;
    if(localStorage.getItem("EnvironmentFlag")=="local"){
        getURL = apiRoleManagementUrl +'/api/' + "UserGroups/GetUserGroupsByRole?role=DCF_CrossBorder_S24";
    }
    else{
        getURL = apiUrl+"UserGroups/GetUserGroupsByRole?role=DCF_CrossBorder_S24"
    }
        $.ajax({
          url: getURL,
          method: "GET",
            headers: {
                "content-Type": "application/json",
                "Authorization" : "Bearer "+localStorage.getItem("idToken"),
                "FormType" : localStorage.getItem("FormType"),
                "UserRoles": getUserRoles()
            },
            success: function (data) {
                deferred.resolve(data);
            },
            error: function (data) {
                showError();
                deferred.reject();
            }
        })
    
    return deferred.promise();
}

function parseBoolean(value){
    var boolValue = false;
    if(typeof(value) !== "undefined" && String(value).toLowerCase() == "true"){
        boolValue = true;
    };
    return boolValue;
}

function UserContext(email, id, loginName, title) {
    this.email = email;
    this.userId = id;
    this.loginName = loginName;
    this.userTitle = title;
}