(function() {
    var fullScrean = document.getElementById('DeltaDelegateControls');
    if(fullScrean) {
        fullScrean.classList.add('container-loader');

        var div = document.createElement('div');
        div.classList.add('loader-animation');

        fullScrean.appendChild(div);
    }
}
)();
