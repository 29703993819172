var blank = " ";

function get3dArraySize(arr) {
    var size = 0
    for (var i in arr) {
        for (var j in arr[i]) {
            size++
        }
    }
    return size;
}

function removeDuplicatedValues(arr) {
    var unique_array = []
    for (var i = 0; i < arr.length; i++) {
        if (unique_array.indexOf(arr[i]) == -1) {
            unique_array.push(arr[i])
        }
    }
    return unique_array
}

$(function () {
    $('.datepicker-format').each(function (i, obj) {
       $(this).datepicker({ dateFormat: 'mm/dd/yy' });
    });
})

function createFolderUrl(libraryName, formId) {
    var folderName = "CrossBorder_" + formId;
    return libraryName + "/" + folderName;
}

function getUTCCurrentTime() {
    var now = new Date();
    return new Date(now.getTime() + now.getTimezoneOffset() * 60 * 1000);
}

function getSPCurrentTime(webUrl) {
    return $.ajax({
        url: webUrl + "/_api/web/RegionalSettings/TimeZone",
        method: "GET",
        headers: { "Accept": "application/json; odata=verbose" }
    }).then(function (data) {
        var offset = data.d.Information.Bias;
        //return new Date( getUTCCurrentTime() - offset * 60 * 1000);
        return new Date().toISOString();
        //return getUTCCurrentTime().toISOString();
    });
}

function getCrossBorderAppConfigurationValue(key) {
    var deferred = $.Deferred();
        $.ajax({
            url: apiUrl+"CrossBorderAppConfiguration/GetList",
            type: "GET",
            
            success: function (data) {
                deferred.resolve(data);
            },
            error: function (data) {
               // showError();
                deferred.reject();
            }
        })
    
    return deferred.promise();
}

function isInteger(value) {
    return value % 1 == 0;
}

function isLengthInvalid(value) {
    return isNaN(value) || !isInteger(value) || value <= 0;
}

function replaceSingleQuote(fileName) {
    return fileName.replace(/'/g, "\'\'");
}

function getFileNameWithoutExtension(fileName) {
    return fileName.replace(/\.[^/.]+$/, "");
}

function formatSignatureDates() {
    $.each($(".signature-date"), function () {
        var input = $(this);
        var inputVal = input.val();
        input.val(getCSTDateTimeString(inputVal,false));
    })
}