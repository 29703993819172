var additionalDocTypesList;
var msgpopup="";
var currentEnv="";
$('#s4-workspace').on('click', '.delete-uploaded-file', deleteUploadedFile);

function deleteUploadedFile(e) {
    e.preventDefault();
    $('#s4-workspace').off('click', '.delete-uploaded-file');
    $('#s4-workspace').on('click', '.delete-uploaded-file', deleteUploadedFile);
    var fileToDelete = $(this);
    //Bug Fix 3711762
    var documentTypeId = fileToDelete.closest(".row").find("input[type=file]").attr("document-type");
      //var documentTypeId =fileToDelete.parents("div.row").find("input[type=file]").attr("document-type")
      //Bug Fix 3711762
    var currentAttachmentEnviroment = getAttachmentEnviroment(fileToDelete);
    currentEnv=currentAttachmentEnviroment;
    var name = fileToDelete.attr('data-filename');
    var folderUrl = createFolderUrl (currentAttachmentEnviroment, gblFormId);
    showModalRemoveAttachment();
    var func = function () {
        deleteFile(folderUrl, name, documentTypeId);
        fileToDelete.parent().parent().remove();
        cleanAttachmentRowWhenDelete(documentTypeId, fileToDelete);
    }
    confirmModalAction(func);
}

function cleanAttachmentRowWhenDelete (documentTypeId, fileToDelete) {
    if (!(haveDocumentTypeAttachments(documentTypeId))) {
        var parent = $("[id=document-type-" + documentTypeId +"]").parent();
        var button = parent.find("button");
        deleteOpenBoxesByDocumentTemplate(documentTypeId);
        deleteReviewIcon(documentTypeId);
        deleteDocumentTypeButtonColorClass(button);
        disableDeleteRedLinesOption(documentTypeId);
        reviewProcessContext = createReviewProcessContext();
        $.when(reviewProcessContext.calculateReview(gblFormId)).done(function(reviewProcessContext){
            setReviewProcessStatus(gblFormId, reviewProcessContext);
        });
    }
    if (!(haveRedlinesDocuments(documentTypeId))) {
        deleteRedlineDocuments(documentTypeId);
    }
}

function isFirstAttachment(documentTypeId) {
    var attachmentSection = $("#attachment-section-document-type-" + documentTypeId);
    if (attachmentSection.find("ul").find("li").length == 1) {
        return true;
    }
    return false;
}

function haveDocumentTypeAttachments(documentTypeId) {
    var attachmentSection = $("#attachment-section-document-type-" + documentTypeId);
    if (attachmentSection.find("ul").find("li").length == 0) {
        return false;
    }
    return true;
}

function haveRedlinesDocuments(documentTypeId) {
    var attachmentSection = $(".redline-attachment-section-" + documentTypeId);
    if (attachmentSection.find("ul").find("li").length == 0) {
        return false;
    }
    return true;
}

function openSelectorFileAttachments (e) {
    $('#s4-workspace').off('click', '.open-selector-file');
    $('#s4-workspace').on('click', '.open-selector-file', openSelectorFileAttachments);
    e.preventDefault();
    var selector = $(this);
    selectFileToUpload(selector); 
}

function openSelectorFileAttachmentsForMemberFirm (e) {
    var selector = $(this);
    selector.off('click');
    selector.on('click', openSelectorFileAttachmentsForMemberFirm);
    e.preventDefault(); 
    selectFileToUpload(selector);  
}

function selectFileToUpload (selector) {
    disableTooltip(selector);
    selector.parent().find('.name-file').val('');
    var selectFile = selector.parent().find('input[type=file]');
    selectFile.replaceWith(selectFile.val('').clone(true));
    selectFile = selector.parent().find('input[type=file]');
    selectFile.click();
    selector.blur();
}

$('#s4-workspace').on('click', '.open-selector-file', openSelectorFileAttachments);

$('#s4-workspace').on('change', 'input[type=file]', function () { 
    var file = $(this);
    var textToInsertName = file.parent().find('.name-file');
    var documentTypeSection = file.parent();
    if (file[0].files[0]) {
        var fileName = file[0].files[0].name;
        textToInsertName.val(fileName);
        var attachmentEnviroment = getAttachmentEnviroment(file);
        startFileUpload(documentTypeSection, attachmentEnviroment);      
    }
    else {
        textToInsertName.val('');
    }
});

function enableTooltip(button) {
    button.siblings(".tooltiptext-dcf").removeClass("hidden");
};

function disableTooltip(button) {
    button.siblings(".tooltiptext-dcf").addClass("hidden");
};

function generateUrlFile(fileName, folderUrl) {
    var parsedFileName = encodeURIComponent(fileName);
    var fileUrl = apiUrl + folderUrl + "/" + parsedFileName;
    return fileUrl;
}

function generateLiAttachment(fileName,folderUrl,formId, documentTypeId, isFinalVersion) {
   var button = $('[id=document-type-' + documentTypeId + ']').parent().find("button");
   var finalVersionButton = '';
   var liClass = 'top-4';
   formId=formId.trim();
   fileName=fileName.trim();
   if(button.hasClass('requires-final-version') === true) {
    finalVersionButton = generateFinalVersionButton(fileName, formId,  documentTypeId, isFinalVersion);
    liClass = 'top-4 display-flex'
   }
   if(folderUrl==additionalDocumentTypesList)
   folderUrl=attachEnviroment;

   var liHtml = '<li class="' + liClass + '" data-filename="' + fileName.toLowerCase() + '">' 
   + finalVersionButton + 
   '<div class="file-row padding-left-5"><a href="javascript:downloadAttachments(`'
   +formId+'`,`'+fileName+'`,`'+folderUrl+'`)"  download="' + fileName + '" class="word-break-all"><span class="search-name break-filename">' 
   + fileName + '</span></a><span id=' + formId + ' class="delete-uploaded-file delete-uploaded-file-style position-absolute-margin-left glyphicon glyphicon-remove float-right" data-filename="' 
   + fileName + '"></span></div></li>';
   return liHtml;
}

function downloadAttachments(formId,fileName,attachEnviroment)
{
    showSpinner();
    var deferred = $.Deferred();    
    $.ajax({
      url: apiDocManagerUrl+"DocumentManager/DownloadFile",
        type: "POST",
        dataType: "json",
        contentType: "application/json",
        headers: {
            "Authorization" : getTokenValue(),
            "FormType" : getFormType(),
            "UserRoles": getUserRoles()
        },
        data: JSON.stringify({FormID: formId.trim() , FileName: fileName,AttachEnviroment:attachEnviroment,PageSource:'CrossBorder'}),
        success: function (response) {
            deferred.resolve(response);
            if(response!=null && response.FileUrl!=null)
            {
                window.open(response.FileUrl, "_blank");
            }
        },
        error: function (data) {
            showError(data); 
            deferred.reject(false);
            hideSpinner();
        }
    })
    hideSpinner();
    return deferred.promise();
}

function generateFinalVersionButton(fileName, formId, documentTypeId, isFinalVersion){
    var finalVersions = $("#attachment-section-document-type-"+documentTypeId+" .marked-as-final").length;
    var finalVersionButton = '<a data-id="'+formId+'" data-file-name="'+fileName+'" data-document-type-id="'+ documentTypeId +'" onmouseover="enableTooltip($(this))" class="'+ ( isFinalVersion || canMarkAsFinal() ? 'enabled' : 'hidden' ) +' mark-as-final '+ (isFinalVersion === true && finalVersions < 1 ? 'marked-as-final' : '' ) +'  tooltip-dcf">Final</a>';
    var finalVersionToolTip = '<span class="tooltiptext-dcf marked-as-final-tooltip">Marked as final version</span>' ;
    return isFinalVersion === true ? finalVersionButton + finalVersionToolTip : finalVersionButton;   
}

function canMarkAsFinal(){
    return $('.attachments-rows').hasClass('mark-as-final-version-enabled');
}

function generateButtonAttachment(fileName,folderUrl,formId,attachRecommendedSectionEnviroment) {
   var fileInfo = getRecommendedFileName(getFileNameWithoutExtension(fileName));
   var tooltip = "";
   var liHtml = '<div class="display-inline-flex" style="margin: 0.5em" {0} data-filename="' + fileName.toLowerCase() + '"><a href="javascript:downloadAttachments(`'
   +formId+'`,`'+fileName+'`,`'+attachRecommendedSectionEnviroment+'`)"  download="' + fileName + '" class="word-break-all btn btn-download-template"><span class="search-name">' + fileInfo.fileName + '</span></a><span data-filename="' + fileName + '"></span></div>';
   if(fileInfo.showTooltip){
        tooltip = 'title="'+ fileName +'"';
   }
   return liHtml.replace("{0}", tooltip);
}

function getRecommendedFileName(fileName) {
    var fileInfo = {
        fileName: fileName,
        showTooltip: false
    };
    var maxLengthFileNameValue = appContext.maxLengthFileName;
    if(fileInfo.fileName.length > maxLengthFileNameValue){
        fileInfo.fileName = fileInfo.fileName.substring(0,maxLengthFileNameValue) + "...";
        fileInfo.showTooltip = true;
    }
    return fileInfo;
}

function showModalConfirmAttach(liUploading) {
    $("#confirm-modal .modal-body")[0].innerHTML = fileWithSameNameMessage;
    moveToBackReviewAssistant();
}

function getFileByServerRelativeUrl(folderUrl,fileName){
   var fileUrl = siteRelativeUrl + "/" + folderUrl + "/" + fileName;
   return apiUrl + "Web/GetFileByServerRelativeUrl(@f)/ListItemAllFields?@f='{0}'".replace("{0}",fileUrl);
}

function attachFile(file, liUploading, formId, documentTypeId, originalFileName,currentAttachmentEnviroment) {
   var deferred = $.Deferred();
//    $.when(adaptFile(file)).done(function(arrayBuffer){
       $.when(uploadFile(formId,file, liUploading,currentAttachmentEnviroment)).then(function (fileMetadata) {
        $.when(uploadFileWithPresignedURL(fileMetadata,file,liUploading)).then(function (fileMetadata) {
            updateFormId(formId, documentTypeId, originalFileName,currentAttachmentEnviroment).done(function(){
                gblItemsLeftToBeSubmitted--
                deferred.resolve();
           });
        });
       });
  // });
   return deferred.promise();
}

function deleteLiUploading (liUploading, originalFileName) {
    var liToDelete = liUploading.parent().find('[data-filename="' + originalFileName.toLowerCase() + '"]');
    liToDelete.parent().remove();  
}

function saveFileLibrary(file, formId, documentTypeId, encodedFileName, liUploading, fileName, currentAttachmentEnviroment) {
    var url;
    gblItemsLeftToBeSubmitted++;
    try{
// var folderName = "CrossBorder_" + formId;
    // $.when(createFolder(currentAttachmentEnviroment, folderName)).then(function (data) {
        var folderUrl = createFolderUrl (currentAttachmentEnviroment, formId);
        url = "";
        //updateFormId(formId,documentTypeId, fileName).done(function(){
            // $.when(getFoldersFilesByName(folderUrl)).then(function (data) {
                // var replaceAction = existFileInFolder(fileName,documentTypeId,data,folderUrl);
                // $.when(replaceAction.fileExists()).then(function(fileExists){
                        $.when(attachFile(file, liUploading,formId, documentTypeId,fileName,currentAttachmentEnviroment)).then(function(){
                           // removeDefaultMessageForRecommendedTemplates(folderUrl);
                            replaceRecommendedTemplateIcon(folderUrl,documentTypeId);
                            createHyperLinkForItem(liUploading, fileName, currentAttachmentEnviroment, formId, documentTypeId);
                            addReviewAssistantModalEventWhenAttach();
                            addDocumentTemplateEventWhenAttach();
                            $.when(addNeedAdditionalReviewFromQRMCompliance(currentAttachmentEnviroment,documentTypeId)).done(function(){
                                if(currentAttachmentEnviroment == attachEnviroment || currentAttachmentEnviroment ==additionalDocumentTypesList){

                                enableDocumentTypeForReview(documentTypeId, null);
                                }
                            });
                            removeSubmitSeries24Button();
                            apiPut(formId);
                        })
                   // }
                //});
            //});
        //});
    //});  
    }
    catch(e){
        localStorage.removeItem("UploadFileInProgress");
    }
                 
}
function addNeedAdditionalReviewFromQRMCompliance(currentAttachmentEnviroment,documentTypeId){
    var deferred = $.Deferred();
    if(currentAttachmentEnviroment == attachEnviroment || currentAttachmentEnviroment ==additionalDocumentTypesList){
        $.when(saveIfNeedAdditionalReviewFromQRMCompliance(documentTypeId)).done (function() {
            deferred.resolve();
        });

    }
    else
    {
        deferred.resolve();
    }
    
    return deferred.promise();
}

function removeDefaultMessageForRecommendedTemplates(folderUrl) {
    if (isInSection(folderUrl, attachRecommendedSectionEnviroment)) {
        var recommendedSection = $("#recommended-document-type-templates");
        recommendedSection.find(".modal-span").remove();  
    }
}

function moveToFrontReviewAssistant(){
    moveToFrontModal($("#review-assistant-modal"));
    moveToFrontModal($("#templates-modal"));
}

function moveToBackReviewAssistant(){
    moveToBackModal($("#review-assistant-modal"));
    moveToBackModal($("#templates-modal"));
}

function replaceHtmlRecommendedTemplateIcon(documentTypeId){
    var templateIcon = $(".template-icon-" + documentTypeId);
    if (templateIcon.find("badge").length == 0) {
        templateIcon.empty();
        templateIcon.append('<a href="/" onclick="return false;" class="notification"><div class="glyphicon glyphicon-file open-templates"></div><span class="badge open-templates"><span class="glyphicon glyphicon-bell"></span></span></a>');
    }
}

function replaceRecommendedTemplateIcon(folderUrl,documentTypeId) {
    if (isInSection(folderUrl, attachRecommendedSectionEnviroment)) {
        replaceHtmlRecommendedTemplateIcon(documentTypeId);
   }
  
}

function createHyperLinkForItem(liUploading, fileName, folderUrl, formId, documentTypeId) {
    var ulToAdd = liUploading.parent();
    liUploading.remove();
    if (isInSection(folderUrl, attachRecommendedSectionEnviroment)) {
        ulToAdd.append(generateButtonAttachment(fileName,folderUrl,formId,attachRecommendedSectionEnviroment));
    } else {
        ulToAdd.append(generateLiAttachment(fileName,folderUrl,formId, documentTypeId, false));
    }
    verifyIfCanDeleteAttachmentWhenAdd(ulToAdd);
}

function isInSection(folderUrl, section) {
    return folderUrl.indexOf(section) > -1
}

function generateAttachments(data, folderUrl) {
    var documentsHtmlData = '';
    
    for (var key in data) {
        var current = data[key];
        var formId = current.FormID;
        var markedAsFinal = current.MarkedAsFinal;
        var name = current.Name;
        var documentTypeId=null;
        if(documentTypeList != undefined && documentTypeList.length > 0)
        {
           var docTypeObj = documentTypeList.filter(obj => obj.DocumentTypeTitle == current.DocumentType);
           if(docTypeObj != undefined && docTypeObj != null && docTypeObj.length>0)
               documentTypeId = docTypeObj[0].DocumentTypeId;
        }
        if(documentTypeId==null || documentTypeId==undefined){
            
            if(additionalDocTypesList!=null && additionalDocTypesList!=undefined && additionalDocTypesList.length > 0)
            {
                var docTypeObj = additionalDocTypesList.filter(obj => obj.DocumentTypeTitle == current.DocumentType);
                if(docTypeObj != undefined && docTypeObj != null && docTypeObj.length>0)
                    documentTypeId = docTypeObj[0].DocumentTypeId;
            }
        }
       
        var section = $('#attachment-section-document-type-' + documentTypeId);        
        var button = $('[id=document-type-' + documentTypeId + ']').parent().find("button");
        documentsHtmlData = generateLiAttachment(name, folderUrl, formId, documentTypeId, markedAsFinal);
        
        changeDocumentTypeButtonColor(button);
        addOpenBoxesByDocumentTemplate(documentTypeId);
        section.find('ul.file-list').append(documentsHtmlData);
    }
}

//GET FILES

// //get files uploaded

function getFiles(formId) {
    var deferred = $.Deferred();    
    $.ajax({
        url: apiUrl + "CrossBorderAttachments/GetList?formID="+formId,
        method: "GET",
        headers: {
            "accept": "application/json;odata=verbose",
            "Authorization" : "Bearer "+localStorage.getItem("idToken"),
            "FormType" : localStorage.getItem("FormType"),
            "UserRoles": getUserRoles()
        },
        success: function (data) {
           
            deferred.resolve(data);
            
        },
        error: function (data) {
            showError(data); 
            deferred.reject(false);
        }
    })
    return deferred.promise();
}

function getAttachmentAuditHistoryCount(formId) {
    var deferred = $.Deferred();    
    $.ajax({
        url: apiUrl + "CrossBorderAttachments/GetAttachmentAuditCount?formID="+formId,
        method: "GET",
        headers: {
            "accept": "application/json;odata=verbose",
            "Authorization" : "Bearer "+localStorage.getItem("idToken"),
            "FormType" : localStorage.getItem("FormType"),
            "UserRoles": getUserRoles()
        },
        success: function (data) {
           
            deferred.resolve(data);
            
        },
        error: function (data) {
            showError(data); 
            deferred.reject(false);
        }
    })
    return deferred.promise();
}

function getRedLineFiles(formId) {
    var deferred = $.Deferred();    
    $.ajax({
        url: apiUrl + "CrossBorderRedLineAttachments/GetList?formID="+formId,
        method: "GET",
        headers: {
            "accept": "application/json;odata=verbose",
            "Authorization" : "Bearer "+localStorage.getItem("idToken"),
            "FormType" : localStorage.getItem("FormType"),
            "UserRoles": getUserRoles()
        },
        success: function (data) {
            deferred.resolve(data);
            
        },
        error: function (data) {
            showError(data); 
            deferred.reject(false);
        }
    })
    return deferred.promise();
}
function createFolder(libraryName, folderName) {
    var deferred = $.Deferred();
    var url = _spPageContextInfo.webAbsoluteUrl + "/_api/web/Folders/add('" + libraryName + "/" + folderName + "')";
    $.ajax({
        url: url,
        type: "POST",
        headers: {
            "accept": "application/json;odata=verbose",
            "content-type": "application/json;odata=verbose",
            "X-RequestDigest": $("#__REQUESTDIGEST").val()
        },
        success: function (data) {
            deferred.resolve(data);
        },
        error: function (error) {
            deferred.reject(error);
        }
    });
    return deferred.promise();
}

function adaptFile(file) {
    var deferred = jQuery.Deferred();
    var reader = new window.FileReader();
    reader.onloadend = function (e) {
        deferred.resolve(e.target.result);
    };
    reader.onerror = function (e) {
        deferred.reject(e.target.error);
    };
    reader.readAsArrayBuffer(file);
    return deferred.promise();
}

function uploadFile(formId, file, liUploading,currentAttachmentEnviroment) {
    var deferred = $.Deferred();
    var fileUploadInput = {};
    fileUploadInput['FormType'] = 'CrossBorder';
    fileUploadInput['FormID'] = formId;
    fileUploadInput['FileName'] = file.name;
    if(currentAttachmentEnviroment=="CrossBorderAdditionalDocumentTypes")
    fileUploadInput['CurrentAttachmentEnviroment'] = "CrossBorderAttachments";
    else
    fileUploadInput['CurrentAttachmentEnviroment'] = currentAttachmentEnviroment;

    $.ajax({
      url: apiDocManagerUrl+"DocumentManager/UploadFile",
      type: "POST",
        data: JSON.stringify(fileUploadInput),
        contentType: "application/json",
        headers: {
            "Authorization" : getTokenValue(),
            "FormType" : getFormType(),
            "UserRoles": getUserRoles()
        },
        success: function (data) {
            deferred.resolve(data);
        },
        error: function (error) {
            deferred.reject(error);
            showError(error);
            liUploading.remove();
        }
    });
    return deferred.promise();
}

function uploadFileWithPresignedURL(preSignedUrlDetails,file,liUploading) {
    var deferred = $.Deferred();
    $.ajax({
      url: preSignedUrlDetails.Url,
      type: "PUT",
        processData: false,
        contentType: false,
        data: file,
        headers: {
            "content-type" : "binary/octet-stream",
            "x-amz-server-side-encryption": "aws:kms",
            "x-amz-server-side-encryption-aws-kms-key-id":preSignedUrlDetails.KMSArn
        },
        success: function (data) {
            deferred.resolve(data);
        },
        error: function (error) {
            deferred.reject(error);
            showError(error);     
            liUploading.remove();       
        }
    });
    return deferred.promise();
}

function getAdditionalDocumentType(formId)
{
    var deferred = $.Deferred();
    $.ajax({
        url: apiUrl +'CrossBorderAdditionalDocumentTypes/GetList?formID='+formId,
        type: "GET",
        headers: {
            "accept": "application/json",
            "content-Type": "application/json",
            "Authorization" : getTokenValue(),
            "FormType" : getFormType(),
            "UserRoles": getUserRoles()
        },
        success: function (data) {
            additionalDocTypesList=data;
            deferred.resolve(data);
        },
        error: function (error) {
            deferred.reject(error);
        }
    });
    return deferred.promise();
}


function updateFormId(formId, documentTypeId, fileName,currentAttachmentEnv) {
    var deferred = $.Deferred();
    addDocumentAttachment(formId, documentTypeId, fileName,currentAttachmentEnv);
    deferred.resolve();
    return deferred.promise();
}

function addDocumentAttachment(formId, documentTypeId, fileName,currentAttachmentEnv,documentList)
{
    var deferred = $.Deferred();
    var body = {}
    var documentType='';
    var _url= apiUrl +'CrossBorderAttachments/Add';
    var isAdditionalDocType = false;

    if(currentAttachmentEnv==attachRedLineEnviroment){
        _url=apiUrl +'CrossBorderRedLineAttachments/Add';
    }
    else if(currentAttachmentEnv==attachRecommendedSectionEnviroment)
    {
        _url=apiUrl +'CrossBorderRecommendedTemplates/Add';
        body['Title'] = getFileNameWithoutExtension(fileName);
    }
    else if(currentAttachmentEnv==additionalDocumentTypesList)
    {
        isAdditionalDocType = true;
        body['Title'] = getFileNameWithoutExtension(fileName);
        body['MarkedAsFinal'] = "TRUE"; // used this to differentiate attachement as additional
    }
    else
        body['Title'] = getFileNameWithoutExtension(fileName);

    if(documentTypeList != undefined && documentTypeList.length > 0)
    {
        var docTypeObj = documentTypeList.filter(obj => obj.DocumentTypeId == documentTypeId);
        if(docTypeObj != undefined && docTypeObj != null && docTypeObj.length>0)
            documentType = docTypeObj[0].DocumentTypeTitle;
    }
    if(currentAttachmentEnv == additionalDocumentTypesList)
    {
        if(documentList != undefined && documentList.length > 0)
        {
                var docTypeObj = documentList.filter(obj => obj.DocumentTypeId == documentTypeId);
                if(docTypeObj != undefined && docTypeObj != null && docTypeObj.length>0)
                    documentType = docTypeObj[0].DocumentTypeTitle;
        }
    }
    
    body['FormId'] = formId;
    body['DocumentType'] = documentType;
    body['Name'] = fileName;
    body['ProjectName'] = xbProjectName;
    body['CreatedBy'] = currentUser.userId;
    body['CreatedByEmail'] = currentUser.email;
    body['ModifiedBy'] = currentUser.userId;
    $.ajax({
        url: _url,
        type: "POST",
        data: JSON.stringify(body),
        headers: {
            "content-Type": "application/json",
            "Authorization" : getTokenValue(),
            "FormType" : getFormType(),
            "UserRoles": getUserRoles()
        },
        success: function (data) {
            deferred.resolve(data);
            if(isXBAdmin)
            {
                $("#AuditFormButton").prop('disabled',false);
            }
        },
        error: function (error) {
            deferred.reject(error);
        }
    });
    return deferred.promise();
}

function updateMarkedAsFinalStatus(url, markedAsFinal) {
    var deferred = $.Deferred();
    var body = { __metadata: { 'type': 'SP.ListItem' } }
    body['MarkedAsFinal'] = markedAsFinal;
    $.ajax({
        url: url,
        type: "POST",
        data: JSON.stringify(body),
        headers: {
            "X-RequestDigest": $("#__REQUESTDIGEST").val(),
            "content-type": "application/json;odata=verbose",
            "content-length": body.length,
            "IF-MATCH": "*",
            "X-HTTP-Method": "PATCH"
        },
        success: function (data) {
            deferred.resolve(data);
        },
        error: function (error) {
            deferred.reject(error);
        }
    });
    return deferred.promise();
}

function getFoldersFilesByName(folderUrl) {
    var deferred = $.Deferred();
    $.ajax({
        url: _spPageContextInfo.webAbsoluteUrl + "/_api/web/GetFolderByServerRelativeUrl('" + folderUrl + "')/Files?$expand=ListItemAllFields",
        type: "GET",
        headers: {
            "accept": "application/json;odata=verbose"
        },
        success: function (data) {
            deferred.resolve(data);
        },
        error: function (error) {
            deferred.reject(data);
        }
    });
    return deferred.promise();
}

function existFileInFolder(fileName, documentTypeId, data, folderUrl) {    
    var files = data.d.results;
    var replaceAction = {
        overwrite: false
    };

    replaceAction.overwrite = files.some(function(f){
        return fileName.toLowerCase() == f.Name.toLowerCase() && documentTypeId  == f.ListItemAllFields.DocumentTypeId;
   })

    replaceAction.fileExists = function(){
        var deferred = $.Deferred();
        var fileValidExists;
        var fileObject;
        var isFileUploaded =  files.some(function(f){
            if(fileName.toLowerCase() == f.Name.toLowerCase()) {
                fileObject = f;
                return true;
            }
        });
        if (isFileUploaded){
            fileValidExists = fileObject.ListItemAllFields.DocumentTypeId && fileObject.ListItemAllFields.CrossBorderFormId;
            if (!fileValidExists) {
                $.when(deleteFile(folderUrl, fileName)).then(function(){
                    deferred.resolve(false);
                });
            } else {
                deferred.resolve(true);
            }
        } else {
            deferred.resolve(false);
        }
        return deferred.promise();
    }
    return replaceAction;
}

function isFileNameValid(fileName) {
    var isValid = true;
    // for (var i = 0; i < invalidCharactersList.length; i++) {
    //     if (IndexOfIllegalCharInUrlLeafName(fileName) != -1) {
    //         isValid = false;
    //         break;
    //     }
    // }
    return isValid;
}
function IndexOfIllegalCharInUrlLeafName(b) {
    for (var a = 0; a < b.length; a++) {
        var c = b.charCodeAt(a);
        if (b.charAt(a) == "." && a == b.length - 1)
            return a;
        if (c < 160 && (b.charAt(a) == "/" || !LegalUrlChars[c]))
            return a
    }
    return -1
}

function isFileLengthValid(fileName) {
    return (fileName.length <= maxAllowedFileLength);
}

function isFileSizeValid(fileSelected){
    return fileSelected.size <= attachmentsMaxSize;
}

function isFileEmpty(fileSelected) {
    return fileSelected.size <= 0; 
}

function deleteFile(folderUrl, fileName) {
    var parsedFileName = encodeURIComponent(fileName);
    var urlFileName = parsedFileName.replace(/'/g, "\'\'");
    var fileNameForDelete=folderUrl.split("/");
    var formIdForDelete=fileNameForDelete[1].split("_");
    //UpdateFormDigest(siteRelativeUrl, _spFormDigestRefreshInterval) ?formID=dsf&filename=sdfds
    return $.ajax({
        url:apiDocManagerUrl+"DocumentManager/DeleteFile?formID="+formIdForDelete[1]+"&filename="+fileName+"&attachEnvironment="+fileNameForDelete[0]+"&pageSource=CrossBorder",
        type: "DELETE",
        contentType: "application/json",
        headers: {
            "Authorization" : getTokenValue(),
            "FormType" : getFormType(),
            "UserRoles": getUserRoles()
        },
        success: function(data) {   
            if(currentEnv=="CrossBorderRedLineAttachments") { 
            $.ajax({
                url:apiUrl+"CrossBorderRedLineAttachments/DeleteAttachment?partitionKeyValue="+formIdForDelete[1]+"&filename="+fileName,
                type: "DELETE",
                contentType: "application/json",
                headers: {
                    "Authorization" : getTokenValue(),
                    "FormType" : getFormType(),
                    "UserRoles": getUserRoles()
                },
                success: function(data) {        
                },
                error: function(data) {
                    showError(data);
                }
            });  
        }
        else if(currentEnv=="CrossBorderRecommendedTemplates") { 
            $.ajax({
                url:apiUrl+"CrossBorderRecommendedTemplates/DeleteAttachment?partitionKeyValue="+formIdForDelete[1]+"&filename="+fileName,
                type: "DELETE",
                contentType: "application/json",
                headers: {
                    "Authorization" : getTokenValue(),
                    "FormType" : getFormType(),
                    "UserRoles": getUserRoles()
                },
                success: function(data) {        
                },
                error: function(data) {
                    showError(data);
                }
            });  
        }
        else{
            $.ajax({
                url:apiUrl+"CrossBorderAttachments/DeleteAttachment",
                type: "POST",
                data: JSON.stringify({partitionKeyValue: formIdForDelete[1] , filename: fileName,ProjectName : xbProjectName,DeletedBy:currentUser.userId,DeletedByEmail:currentUser.email}),
                contentType: "application/json",
                headers: {
                    "Authorization" : getTokenValue(),
                    "FormType" : getFormType(),
                    "UserRoles": getUserRoles()
                },
                success: function(data) {        
                },
                error: function(data) {
                    showError(data);
                }
            });   
        }
        },
        error: function(data) {
            showError(data);
        }
    });
}

function showErrorFileNameInvalidLength() {
    $("#submit-modal .modal-body")[0].innerHTML = fileNameLongerThan128.replace('{0}',maxAllowedFileLength);
    clickSubmitModalButton();
}

function showModalRemoveAttachment() {
    $("#confirm-modal .modal-body")[0].innerHTML = removeFileWarningMessage;
}

function isFileNameEmpty(selectedSection) {
    return selectedSection.find('.name-file').val().length <= 0;
}
function isUploadingSameFile(fileSelected,selectedInputFile,documentTypeId,fileName,encodedFileName,documentTypeSection,attachLocation) {
    var isUploading = false;
    var fileToLowerCase = fileName;   
    if(attachLocation=="CrossBorderRecommendedTemplates"){
       $.when(getRecommendedTemplates()).done(function(data){     
        if(data.length>0){
        data.forEach(f=>{
          if(f.Name.toLowerCase()==fileName.toLowerCase()){isUploading=true;}
           });
           if(isUploading){
            hideSpinner();
            localStorage.removeItem("UploadFileInProgress");
            showModalMessage(fileWithSameNameBlockedMessage);
           }
          else{
            fileUploadAfterValidation(fileSelected,selectedInputFile,documentTypeId,fileName,encodedFileName,documentTypeSection,attachLocation);
          }
        }
        else{
            fileUploadAfterValidation(fileSelected,selectedInputFile,documentTypeId,fileName,encodedFileName,documentTypeSection,attachLocation);
          }

        });
        
       // if($('#recommended-document-type-templates').find('[data-filename="' + fileToLowerCase + '"]').length > 0)
       // return true;
    }   
    else if(attachLocation=="CrossBorderRedLineAttachments"){
        
        if($('.redline-section').find('[data-filename="' + fileToLowerCase + '"]').length > 0)
        return true;
    }
    else{   
        if($('[data-redline="false"]').find('[data-filename="' + fileToLowerCase + '"]').length > 0)
        return true;
      //if ($('[data-filename]').find('[data-filename="' + fileToLowerCase + '"]').length > 0)
    }

   return isUploading;
}

function isValidUpload(selectedSection,fileName, urlFileName, fileSelected) {
    switch(false) {
        case (!isFileNameEmpty(selectedSection)):
            showModalMessage(fileNameEmpty);
            msgpopup=fileNameEmpty;
            return false;
        case isFileNameValid(fileName):
            showModalMessage(fileNameInvalidCharacters);
            msgpopup=fileNameInvalidCharacters;
            return false;
        case isFileLengthValid(urlFileName):
            showErrorFileNameInvalidLength();
            msgpopup=fileSizeInvalid;
            return false;
        case isFileSizeValid(fileSelected):
            showModalMessage(fileSizeLimitMessage);
            msgpopup=fileSizeLimitMessage;
            return false;
        case (!isFileEmpty(fileSelected)):
            showModalMessage(fileNameEmpty);
            msgpopup=fileNameEmpty;
            return false;
        default:
            return true;        
    }
}

function setFileName (fileSelected) {
   if (typeof (fileSelected) == 'undefined') {
        return '';
    }
    return fileSelected.name; 
}

function generateLiUploading(targetSection, fileName) {
    targetSection.find('ul.file-list').append('<li><div class="file-row padding-left-5"><span class="word-break-all" data-filename="' + fileName.toLowerCase() +'">' + fileName +'</span><i class="fa fa-spinner uploading fa-spin font-size-24"></i></div></li>');
    targetSection.parent().find('.name-file').val('');
    return targetSection.find('ul.file-list li').last();
}

function isRedLineDocument(file) {
    return file.closest(".redline-attachment-section-modal").length > 0 || file.parents(".redline-section").length > 0;
}

function isRecommendedSection(file) {
    return file.closest(".attachRecommendedSection").length > 0;
}

function isAdditionalDocumentType(file) {
    var isAdditionalDocType = file.attr('additional-document-type');
    return (isAdditionalDocType=="true");
}

function getAttachmentEnviroment(file) {
    if (isRedLineDocument(file)) {
        return attachRedLineEnviroment;
    }
    else {
        if (isRecommendedSection(file)) {
            return attachRecommendedSectionEnviroment;
        }
        if (isAdditionalDocumentType(file)) {
            return additionalDocumentTypesList;
        }
    }
    return attachEnviroment;
}

function getAttachmentSection(documentTypeSection, attachmentSection, file) {
    if (isRecommendedSection(file)) {
        return $("#recommended-document-type-templates");
    } else {
        if (isRedLineDocument(file)) {
            return $(".redline-attachment-section-modal").find(attachmentSection);
        }
        return $(".attachments-rows").find(documentTypeSection).parents().find(attachmentSection);
    }
}

function getDocumentTypeWhenAttach (file) {
    if (isRedLineDocument(file)) {
        return file.attr("document-type-redline");
    }
    else {
        if (isRecommendedSection(file)) {
            return $(".attachRecommendedSection").attr("attachment-section-document-type");
        }
    }
    return file.attr("document-type");
}
function fileUploadAfterValidation(fileSelected,selectedInputFile,documentTypeId,fileName,encodedFileName,documentTypeSection,currentAttachmentEnviroment){
    var doc_Status = false;
    var attachmentSection = "#attachment-section-document-type-" + documentTypeId;
    var targetSection = getAttachmentSection(documentTypeSection, attachmentSection, selectedInputFile);
    var liUploading = generateLiUploading(targetSection, fileName);
  if(appContext.Documentvalidation==true)
  {
    $.when(checkdocument(fileSelected)).then(function (data) {
        doc_Status = data;
if (!doc_Status) {
hideSpinner();
localStorage.removeItem("UploadFileInProgress");
showModalMessage(docErrorMessage);
liUploading.remove();
}
else{
hideSpinner();
    removeReviewAssistantModalEventWhenAttach();
    removeDocumentTemplateEventWhenAttach();
     
    if (gblFormId == 0) {//if the form isnt saved yet, create a new emptyform and get its id
        emptyPost().done(function (data) {
            gblFormId = data.d.ID;
            //saveFileLibrary(fileSelected, gblFormId, documentTypeId, encodedFileName, liUploading, fileName, currentAttachmentEnviroment);
        }).fail(function (error) {
            showError(error);
        }).always(function (data) { })
        localStorage.removeItem("UploadFileInProgress");
    }
    else {
        saveFileLibrary(fileSelected, gblFormId, documentTypeId, encodedFileName, liUploading,fileName,currentAttachmentEnviroment);
    }
    $('.name-file')[0].value = '';
    hideSpinner();
}
    });
  }
  else
  {
    hideSpinner();
    removeReviewAssistantModalEventWhenAttach();
    removeDocumentTemplateEventWhenAttach();
     saveFileLibrary(fileSelected, gblFormId, documentTypeId, encodedFileName, liUploading,fileName,currentAttachmentEnviroment);
    
    $('.name-file')[0].value = '';
    hideSpinner();
  }
   
}
function isUploadDuplicateFileForIndividualDoc(documentTypeId,fileName,attachLocation){
    let isUpload_Flag=false;
    let selectedAttachments=[];
    fileName=fileName.toLowerCase();
    var attachedList="";
    if(attachLocation=="CrossBorderRecommendedTemplates"){
        attachedList= document.getElementById("recommended-document-type-templates").querySelectorAll(".display-inline-flex");
        attachedList.forEach(v=>{
            selectedAttachments.push((v.dataset["filename"]).toLowerCase());
        });
   
    }
    else if(attachLocation=="CrossBorderRedLineAttachments"){
       // attachedList=document.getElementsByClassName("redline-section redline-attachment-section-"+documentTypeId);
       attachedList=document.getElementsByClassName("redline-attachment-section-modal");
        if(attachedList.length>0)
        attachedList=attachedList[0].children[1].children[0].children;
            Array.from(attachedList).forEach(v=>{
            selectedAttachments.push((v.outerText).toLowerCase());
        });
       
       
    }
    else{
        attachedList= document.getElementById("attachment-section-document-type-"+ documentTypeId).querySelectorAll(".top-4");
        attachedList.forEach(v=>{
            selectedAttachments.push((v.outerText).toLowerCase());
        });
    }
   
    selectedAttachments.forEach(v=>{
        if(v==fileName){
           return isUpload_Flag= true;
        }
    });
    return isUpload_Flag;
}
function IsUploadInProgress(fileName){
    var uploadInProgress=localStorage.getItem("UploadFileInProgress");
    if(uploadInProgress==fileName){
        msgpopup=fileUploading; 
        return false;
    }
    return true;
}
function IsFileExtensionAllowed(filename){  
    var isExtValid=false; 
       if(filename!=undefined&&filename!=null&&filename!=""){
           var fileext = /^.+\.([^.]+)$/.exec(filename);
           fileext=fileext == null ? "" : fileext[1].toLowerCase();
               if(fileext!=""){
               var extensionList=appContext.FileExtensionLists.split(',');
               for(var i=0;i<extensionList.length;i++){
                   if(fileext==extensionList[i]){isExtValid=true;}
               }
                
               }
       }
       return isExtValid;
   }
function startFileUpload(documentTypeSection, currentAttachmentEnviroment) {
	// showSpinner();
    var selectedInputFile = documentTypeSection.find("input[type=file]");
    var documentTypeId = getDocumentTypeWhenAttach(selectedInputFile);
    fileSelected = selectedInputFile[0].files[0];   
    var fileName = setFileName (fileSelected);
    var encodedFileName = replaceSingleQuote(fileName);
    if(IsFileExtensionAllowed(fileName)){   
            if (IsUploadInProgress(fileName) && isValidUpload(documentTypeSection, fileName, encodedFileName, fileSelected)) {
                localStorage.setItem("UploadFileInProgress",fileName);
                if(isUploadDuplicateFileForIndividualDoc(documentTypeId,fileName,currentAttachmentEnviroment)){
                    showModalConfirmAttach(true);
                    var funcOkAttachment = function () {
                        var folderUrl = createFolderUrl(currentAttachmentEnviroment, gblFormId); 
                        currentEnv=currentAttachmentEnviroment; 
                        deleteFile(folderUrl,fileName, documentTypeId);
                        if(currentAttachmentEnviroment=="CrossBorderRecommendedTemplates"){
                            $('#recommended-document-type-templates').find('[data-filename="' + fileName.toLowerCase() + '"]').remove(); 
                        }
                        else if(currentAttachmentEnviroment=="CrossBorderRedLineAttachments"){               
                            $('.redline-attachment-section-modal').find('[data-filename="' + fileName.toLowerCase() + '"]').remove();
                        }
                        else{                  
                            $('#attachment-section-document-type-'+documentTypeId).find('[data-filename="' + fileName.toLowerCase() + '"]').remove();         
                        } 
                       
                        fileUploadAfterValidation(fileSelected,selectedInputFile,documentTypeId,fileName,encodedFileName,documentTypeSection,currentAttachmentEnviroment);
                        moveToFrontReviewAssistant();
                    }
                    var funcCancelAttachment=function(){
                        localStorage.removeItem("UploadFileInProgress");
                        moveToFrontReviewAssistant(); 
                    }
                    confirmModalAction(funcOkAttachment,funcCancelAttachment);
                }
                else if(isUploadingSameFile(fileSelected,selectedInputFile,documentTypeId,fileName,encodedFileName,documentTypeSection,currentAttachmentEnviroment)) {
                    localStorage.removeItem("UploadFileInProgress");
                    if(currentAttachmentEnviroment!="CrossBorderRecommendedTemplates"){
                    hideSpinner();
                    showModalMessage(fileWithSameNameBlockedMessage);
                    }
                }
               else{
                if(currentAttachmentEnviroment!="CrossBorderRecommendedTemplates")
                    fileUploadAfterValidation(fileSelected,selectedInputFile,documentTypeId,fileName,encodedFileName,documentTypeSection,currentAttachmentEnviroment);
                         
            }
				
            }
			else{
				$('.name-file')[0].value = '';
                    showModalMessage(msgpopup);           
		            hideSpinner();
			}
        }
        else{
            hideSpinner();
            showModalMessage(fileExtensionNotValid);
        }
}

function checkdocument(file) {
        var deferred = $.Deferred();
        var fileData = new FormData();
        fileData.append('file', file);
       var url = appContext.docEncryptionUrl;
        $.ajax({
            url: url,
            type: "POST",
            contentType: false, // Not to set any content header  
            processData: false, // Not to process data  
            data: fileData,
            success: function (result) {
				hideSpinner();
                deferred.resolve(result);
            },
            error: function (error) {
				hideSpinner();
                deferred.reject(result);

            }
        });

    return deferred.promise();
}
