
function addRedLineDocumentsToAttachmentSection (documentTypeId) {
    var redLineSection = $(".redline-attachment-section-" + documentTypeId)
    redLineSection.children().empty();
    var redLineDocuments = $(".redline-attachment-section-modal").find("#attachment-section-document-type-" + documentTypeId).find("li").clone();
    redLineDocuments.addClass("full-width");
    redLineDocuments.removeClass("col-xs-5");
    redLineSection.find("ul").append(redLineDocuments);
}

function setDocumentTypeRedlineSection (documentTypeId) {
    $(".redline-attachment-section-modal").find("input[type=file]").attr("document-type-redline", documentTypeId);
}

function generateRedLinesAttachments(data) {
    var documentsHtmlData = '';
    for (var key in data) {
        var current = data[key];
        var formId = current.FormID;
        var name = current.Name;
        var date = getCSTDateTimeString(current.Modified,false); 
        var documentTypeId ='';
        if(documentTypeList != undefined && documentTypeList.length > 0)
        {
            var docTypeObj = documentTypeList.filter(obj => obj.DocumentTypeTitle == current.DocumentType);
            if(docTypeObj != undefined && docTypeObj != null && docTypeObj.length>0)
                documentTypeId = docTypeObj[0].DocumentTypeId;

        }
        
        documentsHtmlData = generateLiAttachment(name,attachRedLineEnviroment,formId);
        var section = $(".redline-attachment-section-" + documentTypeId);
        var button = $('[id=document-type-' + documentTypeId + ']').parent().find("button");
        section.find('ul.file-list').append(documentsHtmlData);
    }
}

function deleteRedlineDocuments (documentTypeId) {
    var redlineSection = $(".redline-attachment-section-" + documentTypeId);
    redlineSection.find("li").remove();
}

function addRedLineDocumentsReviewAssistantModal(documentTypeId) {
    $(".redline-attachment-section-modal").find("#attachment-section-document-type-" + documentTypeId).children().empty();
    var attachments = $(".redline-attachment-section-" + documentTypeId).find("li").clone();
    $(".redline-attachment-section-modal").find("#attachment-section-document-type-" + documentTypeId).children().append(attachments);
}