var fadeInMilliseconds = 1500;
var fadeOutMilliseconds = 4000;
var intervalAutoSave; 
var autoSaveInProgress = false;

function setAutoSave(appContext,canExecuteAutosave){
	var autoSave = autoSaveAllowed(appContext.enableAutoSave, canExecuteAutosave);
	if(autoSave){
		intervalAutoSave = window.setInterval(executeAutoSave, appContext.autoSaveIntervalInMilliseconds);
	}
	return autoSave;
}

function stopAutoSave() {
	clearInterval(intervalAutoSave);
}

function reActivateAutoSave() {
	var canExecuteAutosave = false;
	if (typeof(securityContext) != "undefined") {
		canExecuteAutosave = isAutosaveAllowedByWorkflowState(securityContext);
	}
	setAutoSave(appContext, canExecuteAutosave);
}

function autoSaveAllowed(enableAutoSave, canExecuteAutosave) {
	var autoSave = false;
	if(enableAutoSave!="false" && canExecuteAutosave){
		autoSave = true;
	}

	return autoSave;
}
  
function executeAutoSave() {
	autoSaveInProgress = true;

	$.when(isValidStatus()).then(function(isValid){
		if (isValid) {
			var actionButtons = new ActionButtons();
			actionButtons.disable();
			var invalidFields = new InvalidFields();
			var detachedFields = invalidFields.detach();	
			$.when(doSave()).done(function(){
				displayAutoSaveMessage("AutoSave executed at " + getCSTDateTimeString(new Date(),false));
			}).fail(function(){
				displayAutoSaveMessage("Error while executing AutoSave");
			}).always(function(){
				invalidFields.attach(detachedFields);
				actionButtons.enable();
				autoSaveInProgress = false;
			})
		} else {
			stopAutoSave();
			showModalMessage(updatedFormMessage);
		}
	})
	.fail(function(){
		autoSaveInProgress = false;
		stopAutoSave();
		//showError();
	});
}

function displayAutoSaveMessage(message){
	$("[name='autoSaveMessage']").html(String(message));
	$("#autoSaveContainer").fadeIn(fadeInMilliseconds).fadeOut(fadeOutMilliseconds);
}

function ActionButtons() {
	this.buttons = [
		"btn-approve",
		"return-button",
    	"submit",
		"discontinue-button",
		"on-hold-button"
	];

	this.disable = function () {
		$.each(this.buttons, function() {
			$("." + this).prop("disabled", true);
		})
	}
	this.enable = function () {
		$.each(this.buttons, function() {
			$("." + this).prop("disabled", false);
		})
	}
}

function InvalidFields(){
	this.invalidFields = $(".invalid-field").toArray();
	this.dataInputClasses = [
		"input-data-text",
		"input-data-integer",
		"input-data-currency",
		"input-data-checkbox",
		"input-data-date",
		"input-data-option",
		"input-data-select",
		"input-data-select-lookup",		
		"input-data-toggle-group"
	];

	this.detach = function(){
		var detachList = [];  
		var self = this;
		$.each(this.invalidFields, function(index,element){
      for(var i = 0 ; i < self.dataInputClasses.length;i++){
				var field = $(element);
				if(field.hasClass(self.dataInputClasses[i])){
					field.removeClass("invalid-field");
					field.removeClass(self.dataInputClasses[i]);
					detachList.push({
						elem: element, 
						inputClass: self.dataInputClasses[i]
					});
				}
			}
		 })
		 return detachList;
	} 

	this.attach = function(detachList){				
		$.each(detachList, function(index,item){              
			var field = $(item.elem);
			var inputClass = item.inputClass;
			field.addClass("invalid-field");
			field.addClass(inputClass);			  
		 })	 
	}
}

function getFormStatus(formId) {
    return $.ajax({
        url: apiUrl + "web/lists/getbytitle('" + enviroment + "')/items?$select=FormStatus,FormActionGroup&$filter=Id eq '" + formId + "'",
        type: "GET",
        headers: {
            "accept": "application/json;odata=verbose"
        }
    })
}

function isValidStatus(){
	var deferred = $.Deferred();

	if(gblFormId) {
		$.when(getFormStatus(gblFormId)).then(function(statusData) {
			var savedFormStatus = statusData.d.results[0].FormStatus;
			var savedFormActionGroup = statusData.d.results[0].FormActionGroup;

			if(gblFormStatus === savedFormStatus && gblFormGroup === savedFormActionGroup) {
				deferred.resolve(true);
			} else {
				deferred.resolve(false);
			}

		})
		.fail(function(){
			stopAutoSave();
			//showError();
		});
	} else {
		deferred.resolve(true);
	}

	return deferred.promise();
}