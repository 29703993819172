// Config object to be passed to Msal on creation
function GenerateMsalConfig(appClientId,redirectSite) {
  var msalConfig = {
    auth: {
        clientId: appClientId,
        authority: "https://login.microsoftonline.com/36da45f1-dd2c-4d1f-af13-5abe46b99921/",
        redirectUri: redirectSite
    },
    cache: {
        cacheLocation: "localStorage", // This configures where your cache will be stored
        storeAuthStateInCookie: true, // Set this to "true" if you are having issues on IE11 or Edge
    },
    system: {
        loggerOptions: {
            loggerCallback: (level, message, containsPii) => {
                if (containsPii) {	
                    return;	
                }	
                switch (level) {	
                    case msal.LogLevel.Error:	
                        console.error(message);	
                        return;	
                    case msal.LogLevel.Info:	
                        console.info(message);	
                        return;	
                    case msal.LogLevel.Verbose:	
                        console.debug(message);	
                        return;	
                    case msal.LogLevel.Warning:	
                        console.warn(message);	
                        return;	
                }
            }
        }
    }
 };
 return msalConfig;
}

// Add here scopes for id token to be used at MS Identity Platform endpoints.
const loginRequest = {
    scopes: ["User.Read"]
    //scopes: [""]
};

function getGraphEndpoint(adGroupID){
// Add here the endpoints for MS Graph API services you would like to use.
var graphConfig = {
    graphMeEndpoint: "https://graph.microsoft.com/v1.0/groups/"+adGroupID+"/members?$select=displayName,jobTitle,userPrincipalName&$top=300",
    graphMailEndpoint: "https://graph.microsoft-ppe.com/v1.0/me/messages"
};
return graphConfig.graphMeEndpoint;
}

// Add here scopes for access token to be used at MS Graph API endpoints.
const tokenRequest = {
    scopes: ["Mail.Read"],
    forceRefresh: false // Set this to "true" to skip a cached token and go to the server to get a new token
};

const silentRequest = {
    scopes: ["openid", "profile", "User.Read", "Mail.Read"]
};
