var minYear = 1900;
var dateFormat = 'MM/DD/YYYY';
var dateFormatPicker = 'mm/dd/yy';
var msg = "Date format must be MM/DD/YYYY";
var msgMinYear = "YYYY must be {0} or above";
var msgFutureDate = "The date can not be in the future";
var blank = " ";
var maxHours = 12;

function checkIfDateInDayLightSavingRange(inputdate,isIST)
{
    let utcDate = new Date(inputdate);
    let dateInDayLightRange = false; 
    if(isIST)
    {
      utcDate.setHours(utcDate.getHours() - 5);
      utcDate.setMinutes(utcDate.getMinutes() - 30);
    }
    dateInDayLightRange = moment.utc(utcDate).tz("America/Chicago").isDST();
    return dateInDayLightRange;
}

$(function () {
    $('.datepicker-format').each(function (i, obj) {
        $(this).datepicker({ 
            dateFormat: dateFormatPicker,
            minDate: new Date(minYear, 0, 1),
            maxDate: "0d"
        });
    });
})

$('#s4-workspace').on('click', '.input-data-date', function (e) {
    $(this).datepicker("show");
    document.getElementById("s4-workspace").addEventListener('scroll', scrollOffDatepicker);
})

function scrollOffDatepicker() {
    $('.input-data-date:focus').datepicker("hide");
    $('input:focus').blur();
    document.getElementById("s4-workspace").removeEventListener('scroll', scrollOffDatepicker);
}

function formatDate(date) {
    if (date != null) {
        var d = new Date(date);
        var dateValue = ('0' + (d.getUTCMonth() + 1)).slice(-2) + '/' + ('0' + d.getUTCDate()).slice(-2) + '/' + d.getUTCFullYear();
        return dateValue;
    }
}

function formatDateToSend(date) {
    var d = new Date(date),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();
    if (month.length < 2) month = '0' + month;
    if (day.length < 2) day = '0' + day;
    return [year, month, day].join('-');
}

$(".datepicker-format").change(function(){
    var date = $(this);
    hideNumericValidationMessage(date);
    removeClassToInvalidFields(date);
    var dateValidation = new DateValidation(date);
    if (date.val() != "") {
        dateValidation.validate();
    }
})

$(".datepicker-format").on('keypress', function(e) {    
    return allowDateInputOnKeypress(e);
})

function allowDateInputOnKeypress(e){
    keys = ['0','1','2','3','4','5','6','7','8','9','/', 'Divide'];
    return keys.indexOf(e.key) > -1;
}

function concatenateZeroToNumber(number) {
    return typeof(number) != "undefined" && number.length == 1 ? "0" + number : number;
}

function getCSTDate(date) { 
    var isDateInDayLightSaving = checkIfDateInDayLightSavingRange(date,false);   
    var dateInMilisecondsInLocalTime = new Date(date).getTime();
    var offsetToUTC = new Date().getTimezoneOffset() * 60 * 1000;
    var offsetToCST =  60 * 60 * 1000 * (isDateInDayLightSaving ? appContext.cstToUtcDSTOffset : appContext.cstToUtcOffset);
    var totalMiliseconds = dateInMilisecondsInLocalTime + offsetToUTC - offsetToCST;
    return dateCST = new Date(totalMiliseconds);       
}
function getCSTDateFromIST(date) {    
    var isDateInDayLightSaving = checkIfDateInDayLightSavingRange(date,true);
    var dateInMilisecondsInLocalTime = new Date(date).getTime();
    var offsetToUTC = (-330) * 60 * 1000;
    var offsetToCST =  60 * 60 * 1000 * (isDateInDayLightSaving ? appContext.cstToUtcDSTOffset : appContext.cstToUtcOffset);
    var totalMiliseconds = dateInMilisecondsInLocalTime + offsetToUTC - offsetToCST;
    return dateCST = new Date(totalMiliseconds);       
}
function ConvertUTCToIST(utcDate){
var date=new Date(utcDate);
date.setHours(date.getHours()+5);
date.setMinutes(date.getMinutes()+30);
return date;
}
function getCSTDateTimeString(date,isISTTIme) { 
    if(date=="")
        return "";

        //for migrated data
        if(typeof(date) == "string" && date.includes("/"))
        {
            var toValidDate = new Date(date);
            var finalDate = toValidDate.setTime(toValidDate.getTime() - toValidDate.getTimezoneOffset() * 60000);
            date = new Date(finalDate).toISOString();
        }
    
    var dateCST = (isISTTIme == true ? getCSTDateFromIST(date):getCSTDate(date));
    
    var day = ("0" + dateCST.getDate()).slice(-2);
    var year = dateCST.getFullYear();
    var originalHour = dateCST.getHours();
    var shortHourFormat = originalHour % maxHours || maxHours;
    var hours = ("0" + shortHourFormat).slice(-2);
    var minutes = ("0" + dateCST.getMinutes()).slice(-2);

    var ampm = originalHour >= 12 ? 'PM' : 'AM';
    return day + blank + getMonthName(dateCST) + blank + year + blank + [hours,minutes].join(":") + blank + ampm + blank + "CST";
};

function getCSTDateString(date) {        
    var dateCST = getCSTDate(date);      
    
    var day = ("0" + dateCST.getDate()).slice(-2);
    var year = dateCST.getFullYear();
    
    return day + blank + getMonthName(dateCST) + blank + year;
};

function getMonthName (date) {
    var objDate = new Date(date),
    locale = "en-us",
    month = objDate.toString().split(" ")[1];
    return month;
}

function DateValidation(input){
    this.input = input;
    this.dateFormat = dateFormat;
    this.msg = msg;
    this.msgMinYear = msgMinYear;
    this.msgFutureDate = msgFutureDate;
    this.minYear = minYear;
    this.today = getCSTDate(new Date());
    
    this.initialize = function() {
        this.input.attr("date-value", "");
    }

    this.isFutureDate = function() {
        var milisecondsToday = this.today.valueOf();
        var milisecondsInputDate = getCSTDate(this.input.val()).valueOf();
        return  milisecondsInputDate > milisecondsToday;
    }

    this.formatDate = function() {
        var month = this.input.val().split("/")[0];
        var day = this.input.val().split("/")[1];
        var year = this.input.val().split("/")[2];
        day = concatenateZeroToNumber(day);
        month = concatenateZeroToNumber(month);
        return [month, day, year].join('/'); 
    }

    this.isValidYear = function() {
        var yearResult = {
           validValue : false,
           validLength: false
        }
        var year = this.input.val().split("/")[2];
        yearResult.validValue = parseInt(year) >= this.minYear;
        yearResult.validLength = year.length == 4;
        return yearResult;
   }
       
    this.validate = function() {
        var date = this.formatDate();
        var d = moment(date, this.dateFormat, true);
        var dateValue = "";
        if (d.isValid()) {
            this.input.val(date);
            var validateYear = this.isValidYear();
            if (validateYear.validValue && validateYear.validLength && !this.isFutureDate()) {
                hideNumericValidationMessage(input);
                removeClassToInvalidFields(input);
                dateValue = this.input.val();
            } else {
                var msg = !validateYear.validLength ? this.msg : ( this.isFutureDate() ? this.msgFutureDate  : this.msgMinYear.replace("{0}", this.minYear ));
                showNumericValidationMessage(this.input, msg);
                addClassToInvalidFields(input);         
            }
        } else {
            showNumericValidationMessage(this.input, this.msg);
            addClassToInvalidFields(input);
            
        }
        input.attr("date-value", dateValue);         
    }

    this.initialize();
}