function validateNumericInput(input, regexPattern, msg){
    var result = true;
    var inputdata = input.val().trim();
    if(validateRegexPattern(inputdata, regexPattern)){
        hideNumericValidationMessage(input);
        removeClassToInvalidFields(input);
    }else{
        result = false;
        if(inputdata < 999999.99)
        {
            msg ="Only two decimal points are allowed.";
        }
        showNumericValidationMessage(input, msg);
        addClassToInvalidFields(input);
        input.focus();
    }
    return result;
}

function formatNumericInput(input, inputType){
    var inputdata = input.val().trim();
    if($.isNumeric(inputdata)){
        inputdata = formatNumber(inputdata, inputType);
        input.val(inputdata);
    }
}

function validateRegexPattern(value, regexPattern){
    if(regexPattern.test(value)){
        return true;
    }else {
        return false;
    }
}

function formatNumber(inputdata,inputType) {
    var numberFormat;
    var additionalSymbol = "";
    numeral.nullFormat('');

    switch (inputType) {
        case "currency":
            numberFormat = '0,0';
            break;
        case "float":
            numberFormat = '0,0.00';
            break;
        case "int":
            numberFormat = '(0,0)';
            break;
        case "percentage":
            inputdata/=100;
            numberFormat = '(0,0.0%)';
            break;
        case "negativeCurrency":
            numberFormat = '($0,0)';
            break;
        case "multiply":
            numberFormat = '0,0.0';
            additionalSymbol = 'x';
            break;
        case "oneDecimalCurrency":
            numberFormat = '($0,0.0)';
            break;
        case "positiveInteger":
            numberFormat = '0,0';
            break;
    }
    
    return formatNumeral(numeral(inputdata), numberFormat) + additionalSymbol;
}

function isIntegerFormat(numberFormat) {
    return (numberFormat == "($0,0)" || numberFormat == "(0,0)" || numberFormat == "0,0");
}

function isNegativeNumber(number) {
    return number < 0;
}

function formatNumeral(number, numberFormat) {
    if (isNegativeNumber(number._value) && isIntegerFormat(numberFormat)) {
        number._value = -1 * Math.round(Math.abs(number._value));
    }
    return number.format(numberFormat);
}

function formatNumberToSend(inputdata, allowsFloat) {
    var regexPattern = /[^\d\.]/g;
    var number = inputdata.replace(regexPattern, '');

    if (allowsFloat) {
       return parseFloat(number);
    } else {
        return parseInt(number);
    }   
}

function allowNumericInputOnKeypress(allowsFloat, e){
    keys = ['0','1','2','3','4','5','6','7','8','9']
    if (allowsFloat) {
        keys.push('.');
        keys.push('Del'); //workaround to fix issue in IE related to the "." in the numeric keypad
    }
    return keys.indexOf(e.key) > -1
}

function allowNumericInputOnChange(input, allowsFloat){
   var regexPattern = allowsFloat ? /[^\d\.]/g : /[^\d]/g;
   input.val(input.val().replace(regexPattern, ''));
}

function allowNumericInputWithNAOnChange(e){
    var regexPattern = /[^N\/A\d\.-]/g;
     $(e.target).val($(e.target).val().toUpperCase().replace(regexPattern, ''));
}

function showNumericValidationMessage(input, msg) {
    if(!msg) {
        msg = "Numerical value required";
    }
    var spanToShow = input.siblings(".numeric-validation-msg");  
    spanToShow.html(msg); 
    spanToShow.removeClass("visibility-hidden");
}

function hideNumericValidationMessage(input) {
    var spanToHide = input.siblings(".numeric-validation-msg"); 
    spanToHide.html("");
    spanToHide.addClass("visibility-hidden");
}

function getInvalidFields(){
    var invalidFields = $(".invalid-field").toArray().length;
    return invalidFields;
}

function addClassToInvalidFields(input){
    input.addClass("invalid-field");
}

function removeClassToInvalidFields(input){
    input.removeClass("invalid-field");
}
