// Helper function to call MS Graph API endpoint 
// using authorization bearer token scheme
function callMSGraph(endpoint, accessToken, updateUI) {
    const headers = new Headers();
    const bearer = "Bearer "+localStorage.getItem("accessToken");
    headers.append("Authorization", bearer);

    const options = {
        method: "GET",
        headers: headers
    };

    console.log('request made to Graph API at: ' + new Date().toString());

    fetch(endpoint, options)
        .then(response => response.json())
        .then(response => updateUI(response, endpoint))
        .catch(error => console.log(error));
}
function ModifySearchCriteria(searchVal,criteria){
    try{
        if(searchVal.indexOf(' ')>=0){
            if(criteria=="NormalCriteria"){
            var searchValue=searchVal.split(" ");
            searchVal=searchValue[0]+", "+searchValue[1];
            }
            else{
              var searchValue=searchVal.split(" ");
              searchVal=searchValue[1]+", "+searchValue[0]; 
            }
        }
        return searchVal;
    }
    catch(e){

    }
}
function callMSUSerGraph(search) {
    const headers = new Headers();
    const bearer = "Bearer "+localStorage.getItem("accessToken");
    var searchFirstLastName=ModifySearchCriteria(search,"NormalCriteria");
    var searchLastFirstName=ModifySearchCriteria(search,"DynamicCriteria");
    var endpoint="https://graph.microsoft.com/v1.0/users?$filter=startswith(givenName,'"+search+"')or startswith(displayName,'"+search.trim()+"')or startswith(displayName,'"+searchFirstLastName.trim()+"')or startswith(displayName,'"+searchLastFirstName.trim()+"')or startswith(mail,'"+search+"')  or startswith(surname,'"+search+"') or startswith(givenName,'"+search+"') or startswith(userPrincipalName,'"+search+"')&$top=700";
    headers.append("Authorization", bearer);

    const options = {
        method: "GET",
        headers: headers
    };

    console.log('request made to Graph API at: ' + new Date().toString());

    fetch(endpoint, options)
        .then(response => response.json())
        .then(response => 
            getusergroup(response, endpoint)
            
            )
        .catch(error => console.log(error));
}


async function getAdGroupData() {
    const currentAcc = myMSALObj.getAccountByHomeId(accountId);
    if (currentAcc) {
        const response = await getTokenRedirect(loginRequest, currentAcc).catch(error => {
            console.log(error);
        });
        currentUser = new UserContext(response.account.username, response.account.name);
        accessToken=response.accessToken;
        localStorage.setItem("accessToken",response.accessToken); 
        localStorage.setItem("idToken",response.idToken); 
        localStorage.setItem("userProfileId",currentUser.email); 
        localStorage.setItem("userProfileName",currentUser.userId);
        callMSGraph(getGraphEndpoint(appContext['adGroupId']), response.accessToken, updateUI);
    }
}
async function getAdNewUserData() {
    const currentAcc = myMSALObj.getAccountByHomeId(accountId);
    if (currentAcc) {
        const response = await getTokenRedirect(loginRequest, currentAcc).catch(error => {
            console.log(error);
        });
        currentUser = new UserContext(response.account.username, response.account.name);
        accessToken=response.accessToken;
        localStorage.setItem("idToken",response.idToken); 
        localStorage.setItem("accessToken",response.accessToken); 
        localStorage.setItem("userProfileId",currentUser.email); 
        localStorage.setItem("userProfileName",currentUser.userId);
    }
}