
var spinnerContainer = document.getElementById('DeltaDelegateControls');

function showSpinner() {
  if (spinnerContainer && !spinnerContainer.classList.contains('container-loader')) {
    spinnerContainer.classList.add('container-loader');
    $("#test").css({ position: "fixed"})
  }
}

function hideSpinner() {
  if (spinnerContainer) {
    spinnerContainer.classList.remove('container-loader');
    $("#test").css({ position: "initial"});
  }
}