function FyNumericValidation(input){
    this.input = input;     
    this.regexPattern = /^(\d{2}$|^\d{4})?$/;
    this.msg = "Value must be YY or YYYY";
       
    this.validate = function() {
       validateNumericInput(this.input, this.regexPattern, this.msg);
    }
}

function RevenueNumericValidation(input){
     this.input = input;  
     this.regexPattern = /^(\d{1,3}(,?\d{3})?)?$/;
     this.msg = "Value between 0 and 999,999";
     this.inputType = "currency";
     
     this.validate = function() {
        validateNumericInput(this.input, this.regexPattern, this.msg);
     }

     this.format = function() {
        formatNumericInput(this.input, this.inputType);
     }    
 }

function RevenuePctNumericValidation(input){
    this.input = input;  
    this.regexPattern = /^\d{0,6}(\.\d{1,2})?$/;    
    this.msg = "Value should be between 0 and 999999.99";
    this.inputType = "float";
    
    this.validate = function() {
       var inputdata = this.input.val().trim().split('.');
       //after decimal 0 is entered then ignore it.
       if(inputdata.length > 1 && inputdata.length < 3 && inputdata[1] == 0)
       {
         this.input.val(inputdata[0]);
       }
       var result = validateNumericInput(this.input, this.regexPattern, this.msg);
       return result;
    }

    this.format = function() {
      var inputdata = this.input.val().trim().split('.');
      //if number is entered without decimal then don't append it with .00
      if(inputdata.length == 1)
      {
         this.inputType = "currency";
      }
      formatNumericInput(this.input, this.inputType);
    }    
}
