function getEnvironmentVariable(gblFormStatus) {
  //Use below clientId for sandbox env (local run) and put inside dev section
  //appClientId: "1548b450-59ae-4f5e-b6d2-0088e410bfaf",
  switch (gblFormStatus) {
    case "local":
      var NonProd = [
        {
          adGroupId: "712dc294-e8a5-4cde-85ad-243e9c7a4c43",
          appClientId: "1548b450-59ae-4f5e-b6d2-0088e410bfaf",
          autoSaveIntervalInMilliseconds: "15000",
          cstToUtcOffset: "6",
          cstToUtcDSTOffset: "5",
          docEncryptionUrl: "https://sadvisorysharedservices.deloitte.com/api/document/ValidateUploadedFile",
          enableAutoSave: "false",
          maxLengthComments: "280",
          maxLengthFileName: "30",
          redirectSite: "http://localhost:4200/",
          apiRoleManagementUrl:  'https://localhost:7091',
          apiUrl: "https://localhost:7053/api/",
          apiDocManagerUrl:"https://localhost:7181/api/",
          Documentvalidation: false,
          CCLComments:''
        }
      ]
      return NonProd;
      break;
    case "dev":
      var NonProd = [
        {
          adGroupId: "712dc294-e8a5-4cde-85ad-243e9c7a4c43",
          appClientId: "764270c9-d264-4995-a24a-146ada90ee14",
          autoSaveIntervalInMilliseconds: "15000",
          cstToUtcOffset: "6",
          cstToUtcDSTOffset: "5",
          docEncryptionUrl: "https://sadvisorysharedservices.deloitte.com/api/document/ValidateUploadedFile",
          enableAutoSave: "false",
          maxLengthComments: "280",
          maxLengthFileName: "30",
          redirectSite: "https://dev.usdcf.deloitte.com/",
          apiUrl: "https://dapi.usdcf.deloitte.com/api/",
          Documentvalidation: true,
          CCLComments:''
        }
      ]
      return NonProd;
      break;
    case "qas":
      var NonProd = [
        {
          adGroupId: "712dc294-e8a5-4cde-85ad-243e9c7a4c43",
          appClientId: "764270c9-d264-4995-a24a-146ada90ee14",
          autoSaveIntervalInMilliseconds: "15000",
          cstToUtcOffset: "6",
          cstToUtcDSTOffset: "5",
          docEncryptionUrl: "https://sadvisorysharedservices.deloitte.com/api/document/ValidateUploadedFile",
          enableAutoSave: "false",
          maxLengthComments: "280",
          maxLengthFileName: "30",
          redirectSite: "https://qas.usdcf.deloitte.com/",
          apiUrl: "https://qapi.usdcf.deloitte.com/api/",
          Documentvalidation: true,
          CCLComments:''
        }
      ]
      return NonProd;
      break;
    case "loa":
      var NonProd = [
        {
          adGroupId: "712dc294-e8a5-4cde-85ad-243e9c7a4c43",
          appClientId: "764270c9-d264-4995-a24a-146ada90ee14",
          autoSaveIntervalInMilliseconds: "15000",
          cstToUtcOffset: "6",
          cstToUtcDSTOffset: "5",
          docEncryptionUrl: "https://sadvisorysharedservices.deloitte.com/api/document/ValidateUploadedFile",
          enableAutoSave: "false",
          maxLengthComments: "280",
          maxLengthFileName: "30",
          redirectSite: "https://loa.usdcf.deloitte.com/",
          apiUrl: "https://lapi.usdcf.deloitte.com/api/",
          Documentvalidation: false,
          CCLComments:''
        }
      ]
      return NonProd;
      break;
    case "stg":
      var NonProd = [
        {
          adGroupId: "712dc294-e8a5-4cde-85ad-243e9c7a4c43",
          appClientId: "48b30970-dd51-4456-bc8e-2e879bdbd183",
          autoSaveIntervalInMilliseconds: "15000",
          cstToUtcOffset: "6",
          cstToUtcDSTOffset: "5",
          docEncryptionUrl: "https://sadvisorysharedservices.deloitte.com/api/document/ValidateUploadedFile",
          enableAutoSave: "false",
          maxLengthComments: "280",
          maxLengthFileName: "30",
          redirectSite: "https://stg.usdcf.deloitte.com/",
          apiUrl: "https://sapi.usdcf.deloitte.com/api/",
          Documentvalidation: true,
          CCLComments:''
        }
      ]
      return NonProd;
      break;
    case "prd":
      var Prod = [
        {
          adGroupId: "cfb660ea-e955-421f-bb47-d7d091376443",
          appClientId: "48b30970-dd51-4456-bc8e-2e879bdbd183",
          autoSaveIntervalInMilliseconds: "15000",
          cstToUtcOffset: "6",
          cstToUtcDSTOffset: "5",
          docEncryptionUrl: "https://advisorysharedservices.deloitte.com/api/document/ValidateUploadedFile",
          enableAutoSave: "false",
          maxLengthComments: "280",
          maxLengthFileName: "30",
          redirectSite: "https://usdcf.deloitte.com/",
          apiUrl: "https://api.usdcf.deloitte.com/api/",
          Documentvalidation: true,
          CCLComments:''
        }
      ]
      return Prod;
      break;
  }
}
